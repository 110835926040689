/**
  * active_code模块接口列表
  */

//  import base from './base.js'; // 导入接口域名列表
 import request from '@/api/http.js'; //请求对象
 
 const active_code = {
	
    getAcCodes (params) {
        return request({url: `/user/getAcCodes`,method: 'POST',data:params})
    },
    
    createAcCodes (params) {
        return request({url: `/user/createAcCodes`,method: 'POST',data:params})
    },
 }
 
 export default active_code;