/**
  * api接口的统一出口
*/
// 用户模块接口
import active_code from '@/api/active_code.js';
import user from '@/api/user.js';
import devices from '@/api/devices.js';

import code_type from '@/api/code_type.js';
import replace_code from '@/api/replace_code.js';
import payment from '@/api/payment.js';
import admin from '@/api/admin.js';
// 其他模块的接口……

// 导出接口
export default {
  active_code,
  user,
  devices,
  code_type,
  replace_code,
  payment,
  admin
}
