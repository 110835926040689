/**
  * devices模块接口列表
  */

//  import base from './base.js'; // 导入接口域名列表
import request from '@/api/http.js'; //请求对象
 
const devices = {
   
   /**
    * 获取用户设备
    * 
    * */
   getUserDevices (params) {
       return request({url: `/admin_devices/getUserDevices`,method: 'POST',data:params})
   },
   deleteDev (params) {
       return request({url: `/admin_devices/deleteDev`,method: 'POST',data:params})
   },
 
}

export default devices;