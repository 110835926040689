/**
  * admin模块接口列表
  */

//  import base from './base.js'; // 导入接口域名列表
 import request from '@/api/http.js'; //请求对象
 
 const admin = {
	
	/**
	 * 主账号登录地址
	 * 
	 * */
    readOneAdmin (params) {
        return request({url: `/admin/readOneAdmin`,method: 'POST',data:params})
    },

  
 }
 
 export default admin;