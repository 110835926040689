/**
  * code_type模块接口列表
  */

//  import base from './base.js'; // 导入接口域名列表
 import request from '@/api/http.js'; //请求对象
 
 const code_type = {
    
    searchOne (params) {
        return request({url: `/code_type/searchOne`,method: 'POST',data:params})
    },
 }
 
 export default code_type;