/**
  * payment模块接口列表
  */

//  import base from './base.js'; // 导入接口域名列表
import request from '@/api/http.js'; //请求对象
 
const payment = {
   
   logs (params) {
       return request({url: `/admin_payment/readOneUserPaymentLog`,method: 'POST',data:params})
   },
}

export default payment;