
//  import base from './base.js'; // 导入接口域名列表
 import request from '@/api/http.js'; //请求对象
 
 const apis = {
    
    codeinfo (params) {
        return request({url: `/admin_m_replacecode/codeinfo`,method: 'POST',data:params})
    },
    replaceAndGenCode (params) {
        return request({url: `/admin_m_replacecode/replaceAndGenCode`,method: 'POST',data:params})
    },
    getReplaceCodeLog (params) {
        return request({url: `/admin_m_replacecode/getReplaceCodeLog`,method: 'POST',data:params})
    },
 }
 
 export default apis;