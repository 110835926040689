/**
  * user模块接口列表
  */

//  import base from './base.js'; // 导入接口域名列表
 import request from '@/api/http.js'; //请求对象
 
 const user = {
	
	/**
	 * 主账号登录地址
	 * 
	 * */
    login (params) {
        return request({url: `/admin_sign/signIn`,method: 'POST',data:params})
    },
    modifyUserInfo (params) {
        return request({url: `/user/modifyUserInfo`,method: 'POST',data:params})
    },
    //付费修改用户信息
    modifyUserInfoByPaid (params) {
        return request({url: `/user/modifyUserInfoByPaid`,method: 'POST',data:params})
    },
  
 }
 
 export default user;